import React, { useRef, useState } from "react";
import {
  StyledHero,
  StyledHeroMedia,
  StyledHeroImage,
  StyledHeroLogo,
  StyledHeroMediaExtra,
  StyledHeroMediaExtraCta,
  StyledHeroMediaExtraReadMore,
  StyledHeroSubtitle,
  StyledHeroText,
  StyledHeroTitle,
  StyledHeroScrollDown,
  StyledHeroCtaText,
} from "./style.jsx";
import VimeoPlayer from "../VimeoPlayer/VimeoPlayer";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import { StyledGridRow } from "../../commons/Grid";
import useScrollController from "../../hooks/useScrollController.js";

export default ({
  title,
  subtitle,
  ctaText,
  image,
  video,
  readMore,
  themeColor,
  hasScroller = true,
  logoTitle,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const iconColor = themeColor.yellow ? Colors.yellow : Colors.white;
  const heroEl = useRef(null);
  const { scrollToTop } = useScrollController();

  return (
    <StyledHero {...themeColor} ref={heroEl}>
      <StyledGridRow>
        <StyledHeroText>
          {logoTitle && (<StyledHeroLogo src={logoTitle} loading="lazy" />)}<StyledHeroTitle dangerouslySetInnerHTML={{ __html: title }} />
          <StyledHeroSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
        </StyledHeroText>
      </StyledGridRow>
      {image && (
        <StyledGridRow>
          {typeof image === "string" ? (
            <StyledHeroImage bg={image} />
          ) : (
            <StyledHeroImage bg={image.mobile} bgDesktop={image.desktop} />
          )}
          <StyledHeroCtaText>
            <span>{ctaText}</span>
          </StyledHeroCtaText>
        </StyledGridRow>
      )}
      {video && (
        <StyledGridRow>
          <StyledHeroMedia showVideo>
            <StyledHeroMediaExtra isVisible={!showVideo}>
              <StyledHeroMediaExtraCta>
                <Button
                  handleOnClick={() => setShowVideo(!showVideo)}
                  noAnimation
                >
                  <span>{video.ctaLabel}</span>
                  <Icon icon={icons.play2} color={iconColor} />
                </Button>
              </StyledHeroMediaExtraCta>
              <StyledHeroMediaExtraReadMore
                onClick={() => scrollToTop(heroEl.current.clientHeight + 40)}
              >
                <span>{readMore.text}</span>
              </StyledHeroMediaExtraReadMore>
            </StyledHeroMediaExtra>
            <VimeoPlayer {...video} play={showVideo} setPlay={setShowVideo} showIcon={false} />
          </StyledHeroMedia>
        </StyledGridRow>
      )}
      {hasScroller && (
        <StyledHeroScrollDown
          onClick={() => scrollToTop(heroEl.current.clientHeight + 60)}>
          <Icon icon={icons.chevronDown} color={Colors.yellow} />
        </StyledHeroScrollDown>
      )}
    </StyledHero>
  );
};
