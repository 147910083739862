import styled, { css } from "styled-components";
import {
  BP, Colors, Ratio, Rem, Font,
} from "../../commons/Theme";
import { StyledIcon } from "../Icon/style";
import { StyledButton } from "../Button/style";
import styledMap from "styled-map";
import { scrollDown } from "../../commons/Animations";

export const StyledHero = styled.div`
  position: relative;
  padding-top: ${Rem(25)};
  background-color: ${Colors.transparent};
  
  @media (${BP.ipad}){
    padding-top: ${Rem(50)};
  };

  ${StyledButton} {
    color: ${styledMap`
      yellow: ${Colors.yellow};
      black: ${Colors.white};
      default: ${Colors.white};
    `};
    background-color: ${styledMap`
      yellow: ${Colors.white};
      black: ${Colors.yellow};
      default: ${Colors.yellow};
    `};
  }

  ${() => css`
    ${StyledHeroText} {
      color: ${styledMap`
          yellow: ${Colors.black};
          black: ${Colors.white};
          default: ${Colors.black};
        `};
    }
    ${StyledHeroMediaExtraReadMore} {
      &:before {
        background-color: ${styledMap`
          yellow: ${Colors.black};
          black: ${Colors.white};
          default: ${Colors.yellow};
        `};
      }

      @media (${BP.ipad}) {
        color: ${styledMap`
          yellow: ${Colors.black};
          black: ${Colors.white};
          default: ${Colors.black};
        `};
      }
    }
  `}
`;

export const StyledHeroText = styled.div`
  padding: ${Rem(24)} 0 ${Rem(10)};
 
  @media (${BP.ipad}) {
    padding-bottom: ${Rem(24)};
  }
`;

export const StyledHeroTitle = styled.h1`
  display: inline;
  line-height: ${Rem(65)};
  letter-spacing: 2px;
  font-size: ${Rem(65)};
  margin-bottom: ${Rem(35)};
  font-family: ${Font.babas};
  @media (${BP.ipad}) {
    font-size: ${Rem(105)};
    line-height: ${Rem(105)};
    margin-bottom: ${Rem(35)};
  }
`;

export const StyledHeroSubtitle = styled.h2`
  line-height: ${Rem(42)};
  font-size: ${Rem(38)};
  margin-top: ${Rem(10)};
  font-family: ${Font.babas};
  font-weight: 500;
  @media (${BP.ipad}) {
    margin-top: ${Rem(0)};
    font-size: ${Rem(50)};
    line-height: ${Rem(52)};
    margin-bottom: ${Rem(25)};
}
`;

export const StyledHeroMedia = styled.div`
  position: relative;
  margin-top: ${(props) => props.showVideo ? Rem(0) : Rem(75)}};
`;

export const StyledHeroCtaText = styled.div`
  position: absolute;
  bottom: ${Rem(16)};
  left: 50%;
  transform: translateX(-50%);
  color: ${Colors.white};
  white-space: normal;
  text-align: center;
  line-height: ${Rem(24)};
  width: 90%;
  background-color: rgba(255,160,0,0.5);
`;

export const StyledHeroImage = styled.div`
  position: relative;
  ${Ratio(375, 216)};
  width: 100%;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  
  @media (${BP.ipad}) {
    ${Ratio(1440, 484)};
    
    ${(props) =>
    props.bgDesktop
    && css`
      background-image: url('${(props) => props.bgDesktop}');
    `}
  }
`;
export const StyledHeroLogo = styled.img`
  display:inline;
  margin:0;
  padding:0;
  height:40px;
  margin-right:15px;

  @media (${BP.ipad}) {
    height:60px;
    margin-right:20px;
  }
`;

export const StyledHeroMediaExtra = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-out 0s;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (${BP.ipad}) {
    width: 100%;
    height: auto;
    margin-left: 40px;
    transform: translateY(-50%);
  }

  ${(props) =>
    props.isVisible
    && css`
      opacity: 1;
      pointer-events: all;
    `}
`;

export const StyledHeroMediaExtraCta = styled.div`
  font-size: ${Rem(20)};
  transform: translateY(-50%);
  width: calc(100% - ${Rem(40)});
  margin: 0 auto;

  @media (${BP.ipad}) {
    width: auto;
    margin: 0;
    font-size: ${Rem(30)};
    transform: none;
  }

  ${StyledIcon} {
    width: ${Rem(28)};
    margin-left: ${Rem(24)};
    transition: transform 0.2s linear 0s;

    @media (${BP.ipad}) {
      margin-left: ${Rem(48)};
      width: ${Rem(32)};
    }
  }

  &:hover {
    ${StyledIcon} {
      transform: scale(1.25);
    }
  }

  ${StyledButton} {
    width: 100%;
    height: ${Rem(90)};

    @media (${BP.ipad}) {
      width: ${Rem(560)};
      height: ${Rem(150)};
    }
  }
`;

export const StyledHeroMediaExtraReadMore = styled.div`
  position: absolute;
  bottom: ${Rem(16)};
  left: 50%;
  transform: translateX(-50%);
  color: ${Colors.white};
  white-space: normal;
  text-align: center;
  width: 90%;

  @media (${BP.ipad}) {
    position: relative;
    bottom: auto;
    left: auto;
    transform: translateX(0);
    color: ${Colors.black};
    padding-top: ${Rem(24)};
    text-align: left;
    white-space: nowrap;
    width: auto;
  }

  span {
    letter-spacing: ${Rem(4)};
    text-decoration: none;
    color: inherit;
    font-size: ${Rem(12)};
    cursor: pointer;

    @media (${BP.ipad}) {
      letter-spacing: ${Rem(5)};
      font-size: ${Rem(15)};
    }
  }

  &:before {
    content: '';
    display: none;
    width: ${Rem(46)};
    height: 1px;
    background-color: ${Colors.yellow};
    margin: 0 ${Rem(20)} 0 ${Rem(30)};
    vertical-align: middle;

    @media (${BP.ipad}) {
      display: inline-block;
    }
  }
`;

export const StyledHeroScrollDown = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${Rem(-20)};
  left: 0;
  transform: translate(0, ${Rem(36)});
  z-index: 3;
  text-align: center;
  animation: ${scrollDown} 2s ease-out infinite;

  ${StyledIcon} {
    width: ${Rem(25)};
  }
`;
