import { keyframes } from "styled-components";
import { Rem } from "./Theme";

export const scrollDown = keyframes`
  0% {
    transform: translate3d(0, ${Rem(24)}, 0);
  }
  
  25% {
    transform: translate3d(0, ${Rem(24)}, 0);
  }
  
  50% {
    transform: translate3d(0, ${Rem(42)}, 0);
  }
  
  75% {
    transform: translate3d(0, ${Rem(24)}, 0);
  }
  
  100% {
    transform: translate3d(0, ${Rem(24)}, 0);
  }
`;
